import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Camera, Shield, Lock, Wifi, UserCheck, TrendingUp, Bell, FileText, Activity } from 'lucide-react';

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const SecurityDashboard = () => {
  const [accessLogs, setAccessLogs] = useState([]);
  const [networkActivity, setNetworkActivity] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [breachScenarios, setBreachScenarios] = useState([]);
  const [cameraData, setCameraData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [anomalyScore, setAnomalyScore] = useState(0);
  const [predictiveMaintenance, setPredictiveMaintenance] = useState([]);
  const [incidentReports, setIncidentReports] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateAccessLogs();
      updateNetworkActivity();
      updateEmployeeStatus();
      updateBreachScenarios();
      updateCameraData();
      updateAlerts();
      updateAnomalyScore();
      updatePredictiveMaintenance();
      updateIncidentReports();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const updateAccessLogs = () => {
    setAccessLogs(prev => {
      const newLog = {
        time: new Date().toISOString().substr(11, 8),
        employeeId: Math.floor(Math.random() * 1000),
        location: ['Main Entrance', 'Server Room', 'Office Area', 'Parking Lot'][Math.floor(Math.random() * 4)],
        status: Math.random() > 0.9 ? 'Denied' : 'Granted'
      };
      return [...prev.slice(-4), newLog];
    });
  };

  const updateNetworkActivity = () => {
    setNetworkActivity(prev => {
      const newActivity = {
        time: new Date().toISOString().substr(11, 8),
        ipAddress: `192.168.${Math.floor(Math.random() * 255)}.${Math.floor(Math.random() * 255)}`,
        action: ['File Access', 'Login Attempt', 'Data Transfer', 'System Update'][Math.floor(Math.random() * 4)],
        status: Math.random() > 0.95 ? 'Critical' : (Math.random() > 0.7 ? 'Suspicious' : 'Normal')
      };
      return [...prev.slice(-4), newActivity];
    });
  };

  const updateEmployeeStatus = () => {
    setEmployeeStatus(prev => {
      const newStatus = {
        employeeId: Math.floor(Math.random() * 1000),
        name: ['John Doe', 'Jane Smith', 'Bob Johnson', 'Alice Brown'][Math.floor(Math.random() * 4)],
        department: ['IT', 'HR', 'Sales', 'Operations'][Math.floor(Math.random() * 4)],
        status: ['On Premise', 'Remote', 'On Leave', 'In Transit'][Math.floor(Math.random() * 4)]
      };
      return [...prev.slice(-4), newStatus];
    });
  };

  const updateBreachScenarios = () => {
    if (Math.random() > 0.7) {
      const scenarios = [
        "Brute force attack detected on login portal",
        "Suspicious file download from external IP",
        "Multiple failed admin login attempts",
        "Unusual data exfiltration pattern observed",
        "Potential SQL injection attempt on web application",
        "Unauthorized access attempt to restricted server",
        "Abnormal network scanning activity detected",
        "Possible malware infection on workstation",
        "Phishing email campaign targeting employees",
        "Unauthorized configuration change on firewall"
      ];
      const newScenario = {
        time: new Date().toISOString().substr(11, 8),
        description: scenarios[Math.floor(Math.random() * scenarios.length)],
        severity: ['Low', 'Medium', 'High', 'Critical'][Math.floor(Math.random() * 4)]
      };
      setBreachScenarios(prev => [...prev.slice(-4), newScenario]);
    }
  };

  const updateCameraData = () => {
    setCameraData(prev => {
      const newPoint = {
        time: new Date().toISOString().substr(11, 8),
        online: 90 + Math.floor(Math.random() * 10),
        motionDetected: Math.floor(Math.random() * 30)
      };
      return [...prev.slice(-19), newPoint];
    });
  };

  const updateAlerts = () => {
    if (Math.random() > 0.8) {
      setAlerts(prev => [...prev.slice(-4), `Alert: Unusual activity detected at ${new Date().toISOString().substr(11, 8)}`]);
    }
  };

  const updateAnomalyScore = () => {
    setAnomalyScore(Math.random() * 100);
  };

  const updatePredictiveMaintenance = () => {
    setPredictiveMaintenance(prev => {
      const newMaintenance = {
        equipment: ['Camera 1', 'Door Lock 2', 'Server 3', 'HVAC System'][Math.floor(Math.random() * 4)],
        nextMaintenance: new Date(Date.now() + Math.random() * 7 * 24 * 60 * 60 * 1000).toLocaleDateString(),
        health: Math.floor(Math.random() * 100)
      };
      return [...prev.slice(-3), newMaintenance];
    });
  };

  const updateIncidentReports = () => {
    if (Math.random() > 0.9) {
      setIncidentReports(prev => {
        const newIncident = {
          time: new Date().toISOString().substr(11, 8),
          type: ['Unauthorized Access', 'Network Breach', 'Physical Security Violation', 'Data Leak'][Math.floor(Math.random() * 4)],
          severity: ['Low', 'Medium', 'High', 'Critical'][Math.floor(Math.random() * 4)],
          status: ['Open', 'In Progress', 'Resolved'][Math.floor(Math.random() * 3)]
        };
        return [...prev.slice(-4), newIncident];
      });
    }
  };

  const Card = ({ title, icon, children }) => (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-xl font-bold mb-4 flex items-center">
        {React.createElement(icon, { className: "mr-2" })}
        {title}
      </h2>
      {children}
    </div>
  );

  const TableHeader = ({ children }) => (
    <div className="font-bold text-sm text-gray-500 uppercase">{children}</div>
  );

  const TableCell = ({ children, status }) => (
    <div className={`py-2 ${status === 'Critical' ? 'text-red-600 font-bold' : (status === 'Suspicious' ? 'text-yellow-600' : '')}`}>
      {children}
    </div>
  );

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-4">Advanced Security Operations Center</h1>
      <div className="grid grid-cols-3 gap-4">
        <Card title="Access Logs" icon={Lock}>
          <div className="grid grid-cols-4 gap-2">
            <TableHeader>Time</TableHeader>
            <TableHeader>Employee ID</TableHeader>
            <TableHeader>Location</TableHeader>
            <TableHeader>Status</TableHeader>
            {accessLogs.map((log, index) => (
              <React.Fragment key={index}>
                <TableCell>{log.time}</TableCell>
                <TableCell>{log.employeeId}</TableCell>
                <TableCell>{log.location}</TableCell>
                <TableCell>{log.status}</TableCell>
              </React.Fragment>
            ))}
          </div>
        </Card>
        <Card title="Network Activity" icon={Wifi}>
          <div className="grid grid-cols-4 gap-2">
            <TableHeader>Time</TableHeader>
            <TableHeader>IP Address</TableHeader>
            <TableHeader>Action</TableHeader>
            <TableHeader>Status</TableHeader>
            {networkActivity.map((activity, index) => (
              <React.Fragment key={index}>
                <TableCell>{activity.time}</TableCell>
                <TableCell>{activity.ipAddress}</TableCell>
                <TableCell>{activity.action}</TableCell>
                <TableCell status={activity.status}>{activity.status}</TableCell>
              </React.Fragment>
            ))}
          </div>
        </Card>
        <Card title="Employee Status" icon={UserCheck}>
          <div className="grid grid-cols-4 gap-2">
            <TableHeader>Employee ID</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Department</TableHeader>
            <TableHeader>Status</TableHeader>
            {employeeStatus.map((status, index) => (
              <React.Fragment key={index}>
                <TableCell>{status.employeeId}</TableCell>
                <TableCell>{status.name}</TableCell>
                <TableCell>{status.department}</TableCell>
                <TableCell>{status.status}</TableCell>
              </React.Fragment>
            ))}
          </div>
        </Card>
        <Card title="Breach Scenarios" icon={Shield}>
          <div className="space-y-2">
            {breachScenarios.map((scenario, index) => (
              <div key={index} className={`p-2 rounded ${
                scenario.severity === 'Critical' ? 'bg-red-100 text-red-800' :
                scenario.severity === 'High' ? 'bg-orange-100 text-orange-800' :
                scenario.severity === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                'bg-green-100 text-green-800'
              }`}>
                <div className="font-bold">{scenario.time} - {scenario.severity}</div>
                <div>{scenario.description}</div>
              </div>
            ))}
          </div>
        </Card>
        <Card title="Camera Coverage" icon={Camera}>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={cameraData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="online" stackId="1" stroke="#8884d8" fill="#8884d8" name="Cameras Online" />
              <Area type="monotone" dataKey="motionDetected" stackId="1" stroke="#82ca9d" fill="#82ca9d" name="Motion Detected" />
            </AreaChart>
          </ResponsiveContainer>
        </Card>
        <Card title="Anomaly Detection" icon={TrendingUp}>
          <div className="text-4xl font-bold mb-2">{anomalyScore.toFixed(2)}</div>
          <div className="text-sm text-gray-500">Current Anomaly Score</div>
          <div className="mt-4 h-2 bg-gray-200 rounded-full">
            <div 
              className="h-2 bg-red-500 rounded-full" 
              style={{ width: `${anomalyScore}%` }}
            ></div>
          </div>
        </Card>
        <Card title="Predictive Maintenance" icon={Activity}>
          <div className="grid grid-cols-3 gap-2">
            <TableHeader>Equipment</TableHeader>
            <TableHeader>Next Maintenance</TableHeader>
            <TableHeader>Health</TableHeader>
            {predictiveMaintenance.map((item, index) => (
              <React.Fragment key={index}>
                <TableCell>{item.equipment}</TableCell>
                <TableCell>{item.nextMaintenance}</TableCell>
                <TableCell>{item.health}%</TableCell>
              </React.Fragment>
            ))}
          </div>
        </Card>
        <Card title="Incident Reports" icon={FileText}>
          <div className="grid grid-cols-4 gap-2">
            <TableHeader>Time</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Severity</TableHeader>
            <TableHeader>Status</TableHeader>
            {incidentReports.map((report, index) => (
              <React.Fragment key={index}>
                <TableCell>{report.time}</TableCell>
                <TableCell>{report.type}</TableCell>
                <TableCell>{report.severity}</TableCell>
                <TableCell>{report.status}</TableCell>
              </React.Fragment>
            ))}
          </div>
        </Card>
        <Card title="Real-time Alerts" icon={Bell}>
          <div className="h-48 overflow-y-auto">
            {alerts.map((alert, index) => (
              <div key={index} className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-2">
                <p className="font-bold">Warning</p>
                <p>{alert}</p>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SecurityDashboard;